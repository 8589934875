#faq, #terms, #privacy, #contact, #about {
    background: #FFA400;
    margin-top: 5em;
    padding: 5em 0;
    display: flex;
    justify-content: center;
}

#faqList, #termsBlock, #privacyPolicy, #contactData, #aboutContent {
    padding: 3em 12.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#faqList h2, #termsBlock h2, #privacyPolicy h2, #contactData h2, #aboutContent h2 {
    font-weight: 500;
    color: #000;
}

#faqList>p {
    color: #707070;
    margin-bottom: 3em;
}

#faqList>div>* {
    margin-bottom: 1.5em;
}

#faqList>div, #contactData>div {
    width: 100%;
}

.accordion {
    background-color: #F1F1F1;
    color: #444;
    cursor: pointer;
    padding: 1em 1.5em;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 1em;
    font-weight: 500;
    transition: 0.4s;
    border-radius: 5px;
}

.accordion:hover {
    background-color: #ccc !important;
}

.accordion img {
    margin-left: 1em;
    width: 1.5em;
    object-fit: contain;
    height: 1.5em;
    display: flex;
    align-self: center;
}

.panel {
    padding: 0;
    background-color: white;
    height: 0;
    overflow: hidden;
    font-size: 0.86em;
    transition: 0.4s;
}

#accord .active {
    height: auto !important;
    padding: 1em;
}

#accord img {
    width: 10px;
    filter: invert();
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;
}

#accord img.arrowUp {
    transform: rotate(180deg);
}

#accord {
    border: 1px solid #F1F1F1;
    border-radius: 5px;
    background: #ffffff;
}

@media (max-width: 768px) {
    #faqList, #termsBlock, #privacyPolicy, #contactData, #aboutContent {
        padding: 3em 1.5em;
    }
}
.favlist>a {
    width: calc(100% / 3 - 20px);
}

.favlist {
    flex-wrap: wrap;
    justify-content: space-between;
}

#fav .card {
    position: relative;
}

.redHeart {
    position: absolute;
    top: 1em;
    right: 1em;
    width: 30px !important;
    height: 30px !important;
}

@media (max-width: 768px) {
    .favlist {
        flex-direction: column;
    }
    .favlist>a {
        width: 100%
    }
}
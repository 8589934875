.forVendors {
  color: #000000;
  padding-right: 1rem;
}

.actionNavBtn {
  display: flex;
}

.actionNavBtn img {
  width: 22px;
  height: 22px;
}

.googleClass {
  width: 100%;
  border-left: 2px solid rgb(243, 242, 242);
  border-right: 2px solid rgb(243, 242, 242);
  padding: 0 1rem;
}

.appleClass {
  width: 100%;
  border-right: 2px solid rgb(243, 242, 242);
  padding: 0 1rem;
  margin-right: 1rem;
}

.google {
  background-color: #000000;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  text-align: left;
}

.apple {
  background-color: #000000;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  text-align: left;
}

.google ul {
  margin-left: 10px;
  display: block;
  color: white;
  font-size: 6px;
  list-style: none;
}

.apple ul {
  margin-left: 10px;
  display: block;
  color: white;
  font-size: 8px;
  list-style: none;
}

.google ul li:last-child {
  font-size: 12px;
  width: 100%;
}

.apple ul li:last-child {
  font-size: 12px;
}

@media (max-width: 788px) {
  .Getmobileapp .google {
    background: #000000;
  }

  .actionNavBtn img {
    width: 22px;
    height: 22px;
  }

  .googleClass {
    width: 100%;
    border-left: 2px solid rgb(243, 242, 242);
    border-right: 2px solid rgb(243, 242, 242);
    padding: 0;
  }

  .appleClass {
    width: 100%;
    border-right: 2px solid rgb(243, 242, 242);
    padding: 0 0.5rem;
    margin-right: 0.5rem;
  }

  .google ul {
    margin-left: 10px;
    display: block;
    color: white;
    font-size: 6px;
    list-style: none;
  }

  .apple ul {
    margin-left: 10px;
    display: block;
    color: white;
    font-size: 8px;
    list-style: none;
  }

  .google ul li:last-child {
    font-size: 9px;
    width: 100%;
  }

  .apple ul li:last-child {
    font-size: 9px;
  }

  .apple ul li:first-child {
    font-size: 6px;
  }
}

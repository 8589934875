footer {
    padding-top: 3em;
}

.half {
    list-style: none;
}

.half li {
    margin-top: 0.5em;
}

.half li:first-child {
    font-weight: bold;
    color: black;
}

.half a {
    color: #707070 !important;
}

footer>p {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1em;
}

.subFooter {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    padding-bottom: 0;
}


.subfooterText{
    color: white;
    font-size: 26px;
    margin: 34px 20px;
    width: 100%;
}

.Getmobileapp{
    margin-top: 3rem;
    width: 100%;
}


.Getmobileapp .actionNavBtn img{
    width: 30px;
    height: 36px;
}


.Getmobileapp .googleClass{
    border-right: none;
    border-left: none;
    padding: 0 ;
    margin-right: 15px;
    width: 21%;
}

.Getmobileapp .appleClass{
    border-right: none;
    border-right: none;
    padding: 0 ;
    margin-left: 1rem;
    width: 21%;
}

.Getmobileapp .google ul{
    margin-left: -1rem;
    display: block;
    color: white;
    font-size: 10px;
    list-style: none;
}

.Getmobileapp .apple ul{
    margin-left: -1rem;
    display: block;
    color: white;
    font-size: 10px;
    list-style: none;
}


.Getmobileapp .google ul li:last-child{
    font-size: 16px;
    width: 100%;
}

.Getmobileapp .apple ul li:last-child{
    font-size: 16px;
}


.Getmobileapp .google{
    background: #000000;
    border: 1px solid rgb(151, 148, 148);
    border-radius: 5px;
    padding: 5px 0 5px 10px;
    display: flex;
    text-align: left;
}

.Getmobileapp .apple{
    background-color: #000000;
    border: 1px solid rgb(151, 148, 148);
    border-radius: 5px;
    padding: 5px 0 5px 10px;
    display: flex;
    text-align: left;
}


.subFooter>div {
    /* background-color: #FFA400; */
    /* background: linear-gradient(125.95deg, #FF5353 -5.69%, #EF9999 106.74%); */
    /* border-radius: 20px; */
    width: 100%;
    padding:0;
    display: flex;
    justify-content: space-between;
}

.subFooter p {
    color: black;
}

.subFooter div img {
    width: 40%;
    max-width: 650px;
    margin-right: 2rem;
}

.subFooter>img {
    position: absolute;
    left: -3em;
    bottom: -5em;
    width: 250px;
}



footer {
    padding: 4em 14em 0 14em !important;
}

footer.halfScreen {
    padding: 4em 6em 0 6em !important;
}



@media (max-width: 768px) {

    .subFooter {
        padding: 0%;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        padding-bottom: 0;
        background: none;
        margin-top: 8rem;
    }

    .subFooter div img {
        width: 40%;
        max-width: 650px;
        margin-right: 1rem;
    }

    .subFooter>div {
        background: none;
        color: black;
        border-radius: 0;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: space-between;
    }

    .subFooter .subfooterText {
        color: black;
        font-size: 14px;
        margin: 0;
        width: 100%;
        padding: 5rem 1rem 0 1rem;
    }

    .subFooter .subfooterText p{
        text-align: left;
    }

    .Getmobileapp{
        margin-top: 1rem;
        width: 100%;
    }

    .Getmobileapp .actionNavBtn{
        display: flex;
    }

    .Getmobileapp .actionNavBtn img{
        width: 25px;
        height: 25px;
    }

    .Getmobileapp .googleClass{
        border-right: none;
        border-left: none;
        padding: 0 ;
        margin-right: 0;
        width: 32%;
    }

    .Getmobileapp .appleClass{
        border-right: none;
        border-right: none;
        padding: 0 ;
        /* margin: .5rem 0; */
        width: 32%;
    }

    .Getmobileapp .google ul{
        margin-left: -1rem;
        display: block;
        color: white;
        font-size: 6px;
        list-style: none;
    }

    .Getmobileapp .apple ul{
        margin-left: -1rem;
        display: block;
        color: white;
        font-size: 8px;
        list-style: none;
    }


    .Getmobileapp .google ul li:last-child{
        font-size: 14px;
        width: 100%;
    }

    .Getmobileapp .apple ul li:last-child{
        font-size: 14px;
    }


    .Getmobileapp .google{
        background-color: #dddada;
        border: 1px solid rgb(151, 148, 148);
        border-radius: 5px;
        padding: 5px 0 5px 10px;
        display: flex;
        text-align: left;
    }

    .Getmobileapp .apple{
        background-color: #000000;
        border: 1px solid rgb(151, 148, 148);
        border-radius: 5px;
        padding: 5px 0 5px 10px;
        display: flex;
        text-align: left;
    }


    footer .half:last-child {
        display: none
    }
    footer.halfScreen, footer {
        padding-left: 1.2em !important;
        padding-right: 1.2em !important;
    }
}


@media (max-width: 425px) {
    .Getmobileapp .appleClass {
        border-right: none;
        border-right: none;
        padding: 0;
        margin: .5rem 0;
        width: 100%;
    }
    .Getmobileapp .googleClass {
        border-right: none;
        border-right: none;
        padding: 0;
        margin: .5rem 0;
        width: 100%;
    }

    .Getmobileapp .actionNavBtn {
        display: block;
    }

.subFooter .subfooterText {
    color: black;
    font-size: 14px;
    margin: 0;
    width: 100%;
    padding: 0 1rem;
}
}
#orderHistory, #offers, #fav {
    /* margin-top: 5em; */
}

#orderHistory .flex-column>div {
    transition: all 0.3s ease-in-out;
}

#orderHistory .flex-column>div:hover {
    background-color: #70707020;
}

#orderHistory>div:first-child, #payments>div:first-child, #offers>div:first-child, #fav>div:first-child {
    background-color: #F1F1F1;
    color: #707070;
    padding: 1.5em 0;
}

#orderHistory h3, #offers h3, #fav h3 {
    font-size: 1.2em;
    font-weight: 500;
}

#orderHistory .flex-column>div, #payments>.flex>.paymentCardBox, #offers .flex-column>div {
    box-shadow: 0px 0px 3px #0000004D;
    border-radius: 5px;
    background: white;
    padding: 1.2em;
    margin: 2em 0 0;
}

#orderHistory .flex-column>div:last-child, #offers .flex-column>div:last-child {
    margin-bottom: 4em;
}

.orderCardText {
    margin-left: 1em;
}

.orderCardText p {
    color: #707070;
}

.orderCardText p:nth-child(2) {
    color: #FF5353;
}

.cursor-pointer {
    cursor: pointer !important;
}
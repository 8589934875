.header {
  background: transparent;
  padding: 45px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 5em;
  /* box-shadow: 0px 0px 9px 1px #3c37373d; */
  align-items: center;
  display: flex;
}

.headers {
  background: #fff;
  padding: 40px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 2rem;
  box-shadow: 0px 0px 9px 1px #3c37373d;
  align-items: center;
  display: flex;
}
.desktop__cart {
  display: flex;
  align-items: center;
}
.mobile__cart {
  display: none;
}

.amount {
  /* color: #FF5353; */
  color: #ff5353;
  position: absolute;
  background: #ffffff;
  padding: 0.7em;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  top: -10px;
  right: -20px;
}

.header > img {
  display: none;
}
.headers > img {
  display: none;
}

.header h2,
.headers h2 {
  /* color: #FF5353; */
  color: #ff5353;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: -0.9px;
}

.header .logo-Img,
.headers .logo-img {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
}

.leftNav,
.rightNav {
  display: none;
}

.header img,
.headers img {
  height: 24.88px;
  width: 24.88px;
  margin-left: 0.7rem;
}

.header > img,
.headers > img {
  filter: invert();
}
.sitEatLogo {
  display: flex;
  align-items: center;
  position: relative;
  top: 1rem;
  /* left: 8rem; */
  margin-top: -2.5rem;
}
.menu-Nav {
  margin-left: auto;
  font-weight: 500;
}

.menu-Nav ul {
  display: flex;
  list-style: none;
  margin-right: 1em;
}

.menu-Nav li {
  margin-right: 2em;
}

.menu-Nav li img {
  margin-right: 0.4em;
}

.menu-Nav li:last-child img:last-child {
  width: 10px;
  margin-left: 1em;
}

.menu-Nav div span {
  /* color: #ffffff; */
  margin-right: 0.8em;
  margin-top: 0.5em;
  font-weight: normal;
  /* background: #ff5353; */
  /* padding: 0.7rem 0.5rem; */
  border-radius: 50%;
  width: auto;
  /* height: auto; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-num {
  color: #ffffff !important;
  margin-right: 0.8em;
  margin-top: 0.5em;
  font-weight: normal;
  background: #ff5353 !important;
  padding: 0.7rem 0.5rem;
}

.menu-Nav [href="/login"] img:last-child {
  width: 10px;
  margin-left: 1em;
}

/* .header a,
.header div {
  display: flex;
  align-items: center;
} */

.menu-Nav a {
  padding: 1.6em 0;
  color: gray;
  border-bottom: 5px solid transparent;
  transition: border-bottom 0.5s ease;
}

.menu-Nav a:hover {
  border-bottom: 5px solid white;
}

.menu-Nav-Active {
  border-bottom: 5px solid white !important;
  color: gray;
  animation: fadeIn 0.5s linear;
}

@keyframes fadeIn {
  0% {
    border-bottom-color: transparent;
  }
  100% {
    border-bottom-color: white;
  }
}

@media (max-width: 768px) {
  #siteat {
    font-size: large;
    margin-left: -9rem;
    font-weight: 700;
  }

  /* .mobile__cart{
        display: block;
        margin: -3rem -5rem 0 0;

    } */

  .Getmobileapp_mobile,
  .forVendors {
    display: block;
  }

  .menu-Nav {
    margin-left: 0;
  }
  .menu-Nav ul {
    display: none;
  }
  .header {
    justify-content: space-between;
    background: transparent;
    margin-bottom: -10rem;
  }
  .header > *,
  .headers > * {
    cursor: pointer;
  }
  .header > img,
  .headers > img {
    display: block;
  }

  /* .header-siteat {
    display: flex;
    align-items: center;
  } */
  .sitEatLogo {
    display: flex;
    align-items: center;
    position: relative;
    top: 1rem;
    left: 8rem;
    margin-top: -2.5rem;
  }

  .leftNav,
  .rightNav {
    display: block;
  }
  .leftNav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 100%;
    z-index: 2;
    transition: all 0.25s;
    background-color: white;
    opacity: 0;
    overflow-y: scroll;
    visibility: hidden;
  }
  .leftNav > ul {
    list-style: none;
    margin-bottom: 2em;
  }
  .leftNav > ul li {
    padding: 1.5em;
    border-bottom: 1px solid #c1c1c1;
  }
  .leftNav > ul li a,
  .leftNav > ul li p {
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    color: #646464;
  }
  .leftNav > ul li a img,
  .leftNav > ul li p img {
    margin-right: 2.5em;
    width: 2em;
  }
  .leftNav div:first-child {
    display: flex;
    justify-content: flex-end;
  }
  .leftNav div p {
    font-size: 3em;
    color: #000000;
    margin: 0 0.5em;
    cursor: pointer;
  }
  .activeLeftNav {
    right: 0;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }
  .rightNav {
    position: fixed;
    top: 0;
    left: 100%;
    bottom: 0;
    right: 0;
    z-index: 2;
    transition: all 0.25s;
    background-color: white;
    opacity: 0;
    font-size: 1.14em;
    font-weight: 500;
    visibility: hidden;
    display: flex;
    flex-direction: column;
  }
  .activeLeftNav .divider {
    margin-top: auto;
  }
  .rightNav p:first-child {
    font-size: 3em;
    color: #000000;
    margin: 0 0.5em;
    cursor: pointer;
    padding: 0 !important;
  }
  .rightNav p {
    margin-bottom: 2em;
    padding: 0 1.2em;
  }
  .rightNav p:nth-child(3) {
    color: #ff5353;
    margin-bottom: 1em;
  }
  .rightNav > div > div {
    padding: 1em 1.2em;
  }
  .rightNav > div > div:not(:last-child) {
    border-bottom: 1px solid #c1c1c1;
  }
  .rightNav .suggestionBox {
    background: #ffffff;
    border: 0.5px solid #b4b4b4;
    border-radius: 5px;
    height: 4.2em;
    margin-top: 1em;
    margin-bottom: 2em;
    overflow: hidden;
    margin-left: 1.2em;
    margin-right: 1.2em;
  }
  .suggestionBox > div {
    width: 56px;
    background-color: #ffda2a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .suggestionBox img {
    height: 1.15em !important;
    width: 1.15em !important;
    margin-top: 0 !important;
  }
  .suggestionBox input {
    width: 100%;
    padding-left: 1em;
    border: none;
  }
  .activeRightNav {
    left: 0;
    visibility: visible;
    opacity: 1;
  }
  .rightNav div > .numberBox {
    margin: 0 1em;
    border: 1px solid #c1c1c1;
    padding: 0.1em 2em;
    border-radius: 5px;
  }
  .rightNav .cartControls {
    padding: 0.2em;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    height: 16px;
    width: 16px;
    align-self: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media (max-width: 640px) {
  /* .header a,
  .header div {
    display: none;
  } */
  .desktop__cart {
    display: none;
  }
  .mobile__cart {
    display: block;
    margin: -2rem -1rem 0 0;
  }
  .sitEatLogo {
    /* width: 100%; */

    position: absolute;
    left: 60%;
    transform: translateX(-40%);
  }
}
/* @media (max-width: 540px) {
  .header a,
  .header div {
    display: none;
  }
  .desktop__cart {
    display: none;
  }
  .mobile__cart {
    display: block;
    margin: -2rem -1rem 0 0;
  }
  .sitEatLogo {
    width: 100%;

    position: absolute;
    left: 60%;
    transform: translateX(-40%);
  }
} */

@media (max-width: 425px) {
  #siteat {
    font-size: large;
    margin-left: -3rem;
    font-weight: 700;
  }

  .menu-Nav {
    margin-left: 0;
  }

  .menu-Nav ul {
    display: none;
  }

  .header {
    justify-content: space-between;
    background: transparent;
    margin-bottom: -10rem;
  }
  .headers {
    justify-content: space-between;
    /* background: transparent; */
    margin-bottom: -10rem;
  }

  .header > *,
  .headers > * {
    cursor: pointer;
  }

  .header > img,
  .headers > img {
    display: block;
  }

  .leftNav,
  .rightNav {
    display: block;
  }

  .leftNav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 100%;
    z-index: 2;
    transition: all 0.25s;
    background-color: white;
    opacity: 0;
    overflow-y: scroll;
    visibility: hidden;
  }

  .leftNav > ul {
    list-style: none;
    margin-bottom: 2em;
  }

  .leftNav > ul li {
    padding: 1.5em;
    border-bottom: 1px solid #c1c1c1;
  }

  .leftNav > ul li a,
  .leftNav > ul li p {
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    color: #646464;
  }

  .leftNav > ul li a img,
  .leftNav > ul li p img {
    margin-right: 2.5em;
    width: 2em;
  }

  .leftNav div:first-child {
    display: flex;
    justify-content: flex-end;
  }

  .leftNav div p {
    font-size: 3em;
    color: #000000;
    margin: 0 0.5em;
    cursor: pointer;
  }

  .activeLeftNav {
    right: 0;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }

  .rightNav {
    position: fixed;
    top: 0;
    left: 100%;
    bottom: 0;
    right: 0;
    z-index: 2;
    transition: all 0.25s;
    background-color: white;
    opacity: 0;
    font-size: 1.14em;
    font-weight: 500;
    visibility: hidden;
    display: flex;
    flex-direction: column;
  }

  .activeLeftNav .divider {
    margin-top: auto;
  }

  .rightNav p:first-child {
    font-size: 3em;
    color: #000000;
    margin: 0 0.5em;
    cursor: pointer;
    padding: 0 !important;
  }

  .rightNav p {
    margin-bottom: 2em;
    padding: 0 1.2em;
  }

  .rightNav p:nth-child(3) {
    color: #ff5353;
    margin-bottom: 1em;
  }

  .rightNav > div > div {
    padding: 1em 1.2em;
  }

  .rightNav > div > div:not(:last-child) {
    border-bottom: 1px solid #c1c1c1;
  }

  .rightNav .suggestionBox {
    background: #ffffff;
    border: 0.5px solid #b4b4b4;
    border-radius: 5px;
    height: 4.2em;
    margin-top: 1em;
    margin-bottom: 2em;
    overflow: hidden;
    margin-left: 1.2em;
    margin-right: 1.2em;
  }

  .suggestionBox > div {
    width: 56px;
    background-color: #ffda2a;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .suggestionBox img {
    height: 1.15em !important;
    width: 1.15em !important;
    margin-top: 0 !important;
  }

  .suggestionBox input {
    width: 100%;
    padding-left: 1em;
    border: none;
  }

  .activeRightNav {
    left: 0;
    visibility: visible;
    opacity: 1;
  }

  .rightNav div > .numberBox {
    margin: 0 1em;
    border: 1px solid #c1c1c1;
    padding: 0.1em 2em;
    border-radius: 5px;
  }

  .rightNav .cartControls {
    padding: 0.2em;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    height: 16px;
    width: 16px;
    align-self: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

@media (max-width: 320px) {
  /* .sitEatLogo {
    display: block;
    position: relative;
    top: 1rem;
        left: 2rem;
    margin: 0;
  } */
}

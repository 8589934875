#vendorReg {
    background-color: #FF5353;
    display: flex;
    justify-content: center;
    padding: 3em 0;
    margin-top: 5em;
}

#vendorForm {
    padding: 2.4em 1.2em;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#vendorForm h2 {
    font-weight: 500;
}

#vendorForm p {
    text-align: center;
    color: #707070;
    font-weight: 400;
    line-height: 21px;
    margin: 1em 0 2em;
}

#vendorForm>div>input {
    margin: 0.75em 0;
    padding: 1em 1.6em;
    color: #B4B4B4;
    font-size: 1em;
    border: 0.5px solid #C4C4C4;
    border-radius: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

#vendorForm>div>div {
    margin-top: 2em;
}

#vendorForm label {
    margin-left: 1em;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

#vendorForm button {
    background-color: #FF5353;
    color: white;
    padding: 1em;
    text-align: center;
    border-radius: 5px;
    margin: 2em 0 5em;
    border: none;
    font-size: 1.2em;
    font-weight: 500;
}
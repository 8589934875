.container {
  width: 100%;
  /* padding-left: -1rem;
    padding-right: -1rem; */
}

.desktopBanner {
  display: flex;
  align-items: center;
  /* min-height: 250px; */
  height: 26rem;
}

.instructions {
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.instructions h2 {
  font-size: 36px;
  font-weight: 600;
}

.instructions > * {
  padding: 3em 0 0;
}

.instructions img {
  width: 20%;
}

.instructions img[alt="redBurger"] {
  width: 100%;
  padding-right: 1em;
}

.instructions img[alt="click here to order"] {
  width: 100%;
  min-height: 100px;
}

.instructions [class="align-items-center"] {
  margin-top: -2em;
  font-weight: bold;
}

#howItWorks {
  /* background-color: #FFA400; */
  /* display: flex; */
  margin-top: 1rem;
  align-items: center;
  flex-direction: column;
  padding: 5em 0;
  color: black;
}

#howItWorks h3 span {
  color: #ff5353;
}

#howItWorks > div {
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
}

#howItWorks h3 {
  margin-bottom: 4em;
  font-size: 1.6em;
  text-align: center;
  /* margin-left: 8.5%; */
}

#howItWorks h2 {
  margin-top: 2em;
  font-size: 16px;
}

#howItWorks > div > * {
  margin-right: 6em;
  align-items: center;
  width: 30%;
}

#howItWorks > div > :last-child {
  margin: 0;
}

#howItWorks img {
  width: 70%;
  height: 200px;
  object-fit: contain;
}

.redefine {
  margin: -10rem -2rem 2rem -2rem;
  padding-top: 3rem;
  display: flex;
  justify-content: center;
}

.redefine .redefineImg {
  /* width: 661px; */
  width: 50%;
  /* background-color: aqua; */
  justify-content: center;
  display: flex;
}

.redefine .redefineTxt {
  text-align: left;
  justify-content: center;
  display: flex;
  /* background-color: #ff5353; */
  width: 40%;
  align-items: center;
  padding-left: 5rem;
}

.redefine .redefineTxt div {
  text-align: left;
  justify-content: center;
  /* background-color: #53ff53; */
  width: 100%;
}

.redefine img {
  width: 90%;
  object-fit: contain;
}

.redefine h2 {
  font-weight: 500;
  font-size: x-large;
  line-height: 1.6em;
  width: 90%;
  /* background: cadetblue; */
}

.redefine p {
  font-size: 15px;
  margin-top: 2rem;
  width: 70%;
  line-height: 2.5rem;
}

.redefine span {
  color: #ff5353;
}

.end {
  margin-bottom: 3em;
}

.flex {
  display: flex;
}

.justify-between {
  display: flex;
  justify-content: space-between !important;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;
}

.desktopBanner img {
  width: calc(100% / 3);
  align-self: end;
  margin-top: auto;
}

/* .banner {
    margin-top: 7em;
} */

.banner > a {
  margin-top: 2em;
  margin-bottom: 2em;
  cursor: pointer;
  width: 107%;
}

.categoryLead {
  margin-top: 3rem;
  font-size: 32px;
  align-items: center;
  display: flex;
  font-weight: 500;
}

.categoryLead img {
  width: auto !important;
  margin: 0;
  margin-left: 0.3em;
  padding: 0;
}

.mealsSection {
  width: 100%;
  margin-top: 4em;
}

.mealsSection > div:first-child {
  width: 40%;
  margin-left: -50px;
}

.mealsSection > div:nth-child(2) {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mealsSection img {
  width: 80%;
}

section img {
  margin-top: 1em;
}

section.container label {
  font-size: 1.5em;
  font-weight: 500;
}

section.container p {
  font-weight: 500;
}

section.container img {
  width: 100%;
  border-radius: 5px;
  max-height: 360px;
  object-fit: cover;
}

.half {
  width: 47%;
}

.mealsSection .half {
  margin-right: 1em;
  min-height: 200px;
  object-fit: cover;
}

.mealsSection .half:last-child {
  margin-right: 0;
}

.demo {
  padding-bottom: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
}

.demo label {
  text-align: center;
  margin-top: 2.43em;
  font-size: 1em !important;
}

.demo label span {
  color: #ff5353;
}

.demo > span,
#aboutContent > span {
  color: #ff5353;
  font-size: 1.143em !important;
  font-weight: 500;
  padding: 0.8em 3.6em;
  cursor: pointer;
  margin: 2em 0 3em 0;
  border: 1px solid rgba(95, 95, 95, 0.2);
  box-shadow: 0px 0px 22px -1px darkgray;
  border-radius: 5px;
}

.mobile {
  display: none;
}

.mobile > img {
  width: 93% !important;
  margin: 0 auto;
  display: flex;
}

.desktop {
  display: block;
}

.cursorP {
  cursor: pointer;
}

.workWithUs {
  color: #ff5353;
  font-weight: 600;
  text-decoration: underline;
  border-bottom: 3px solid #ff5353;
}

.workWithUs:hover {
  scale: 20px;
}

.footer-section {
  padding-bottom: 10rem;
}

/* .redefine{
    display: block;
} */

@media (max-width: 768px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .banner img {
    width: 100%;
  }
  #howItWorks {
    display: block;
    margin-top: -9rem;
  }

  #howItWorks h3 {
    font-size: 15px;
    text-align: center;
    margin-left: 0;
  }

  #howItWorks img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }

  #howItWorks .howItWorks {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: -4rem;
  }

  #howItWorks .howItWorks div img {
    display: block;
    width: 40%;
    margin: 10rem auto 0 auto;
  }

  #howItWorks .howItWorks .flex-column {
    display: block;
    width: 100%;
    margin-bottom: 3rem;
  }

  #howItWorks .howItWorks .text-center {
    margin: 1rem 0;
    font-size: 13px;
    padding: 0 4rem;
  }

  #howItWorks .howItWorks .text-center:first {
    font-weight: 700;
    color: blue;
  }

  .redefine img {
    width: 50%;
  }

  .redefine {
    margin-top: -3rem;
    border-top: 1px solid rgb(204, 204, 204);
    padding-top: 4rem;
    display: block;
  }

  .redefine .redefineImg {
    margin-top: -7rem;
    /* width: 661px; */
    width: 100%;
    /* background-color: aqua; */
  }

  .redefine .redefineTxt {
    text-align: left;
    width: 100%;
    padding: 6%;
    margin-top: -12rem;
    text-align: center;
  }
  */ .redefine div {
    width: 100%;
  }

  .redefine h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.6em;
    margin-top: 3rem;
    width: 100%;
    text-align: center;
  }

  .redefine p {
    font-size: 13px;
    padding: 1rem 3rem;
    width: 100%;
    text-align: center;
    margin-top: -0.1rem;
    line-height: 2rem;
  }

  .redefine span {
    color: #ff5353;
  }

  .mealsSection .half:last-child {
    display: none;
  }
  .categoryLead {
    font-size: 2em;
  }
  .mealsSection > div:first-child {
    display: none;
  }
  .mealsSection > div:nth-child(2) {
    width: 100%;
  }
  .mealsSection {
    margin-top: 0;
  }
}

@media (max-width: 425px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .banner img {
    width: 100%;
  }

  #howItWorks {
    display: block;
    margin-top: -4rem;
  }

  #howItWorks h3 {
    font-size: 15px;
    text-align: center;
    margin-left: 0;
  }

  #howItWorks img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }

  #howItWorks .howItWorks {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: -4rem;
  }

  #howItWorks .howItWorks div img {
    display: block;
    width: 40%;
    margin: auto;
  }

  #howItWorks .howItWorks .flex-column {
    display: block;
    width: 100%;
    margin-bottom: 3rem;
  }

  #howItWorks .howItWorks .text-center {
    margin-top: -2rem;
    font-size: 13px;
    padding: 0 4rem;
  }

  #howItWorks .howItWorks .text-center:first {
    font-weight: 700;
    color: blue;
  }

  .redefine img {
    width: 80%;
  }

  .redefine {
    margin-top: -3rem;
    border-top: 1px solid rgb(204, 204, 204);
    padding-top: 6rem;
    display: block;
  }

  .redefine .redefineImg {
    margin-top: -7rem;
    /* width: 661px; */
    width: 100%;
    /* background-color: aqua; */
  }

  .redefine .redefineTxt {
    text-align: left;
    width: 100%;
    padding: 6%;
    margin-top: -8rem;
    text-align: center;
  }

  */ .redefine div {
    width: 100%;
  }

  .redefine h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.6em;
    margin-top: 3rem;
    width: 100%;
    text-align: center;
  }

  .redefine p {
    font-size: 13px;
    padding: 1rem 3rem;
    width: 100%;
    text-align: center;
    margin-top: -0.1rem;
    line-height: 2rem;
  }

  .redefine span {
    color: #ff5353;
  }

  .mealsSection .half:last-child {
    display: none;
  }

  .categoryLead {
    font-size: 2em;
  }

  .mealsSection > div:first-child {
    display: none;
  }

  .mealsSection > div:nth-child(2) {
    width: 100%;
  }

  .mealsSection {
    margin-top: 0;
  }
}

#contactData {}

#contactData li {
    color: #FF5353;
    font-weight: 500;
    margin-top: 1.5em;
    padding-bottom: 1.5em;
    border-bottom: 0.5px solid #c4c4c4;
}

#contactData li {
    color: #FF5353 !important;
}

#contactData ul {
    margin: 3em 0;
    list-style: none;
}

#contactData label {
    color: black
}
#aboutContent p {
    text-align: center;
    color: #707070;
    margin-top: 1.8em;
}

#aboutContent> :nth-child(3), #aboutContent> :nth-child(6) {
    margin: 1.5em 0;
    font-size: 1.2em;
}

#aboutContent ul {
    list-style: none;
    color: #707070;
    margin-top: 2em;
}

#aboutContent li:last-child {
    margin: 1em 0 0;
    color: #FF5353;
    font-weight: 500;
}

#aboutContent>span {
    margin-top: 3em;
}

#aboutContent>div {
    background-color: #FF5353;
    width: 146%;
}

#aboutContent>div img {
    width: 150px;
    margin: 2em auto;
    display: flex
}
#termsBlock>div, #privacyPolicy>div {
    margin-top: 3em;
}

#termsBlock, #privacyPolicy {
    color: #707070;
}

#termsBlock p, #privacyPolicy p {
    font-size: 0.85em;
    margin-bottom: 2.5em;
    margin-top: 0.7em;
}

#termsBlock p:first-child, #privacyPolicy p:first-child {
    font-weight: 500;
}

#termsBlock label, #privacyPolicy label {
    font-weight: 500;
    font-size: 0.85em;
}
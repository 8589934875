.eventCenter h3 {
  font-weight: 500;
  margin-top: 1em;
}

.react-tabs__tab-list {
  border-bottom: none !important;
  text-align: center;
}

.react-tabs__tab-list li {
  padding: 1rem;
}

.react-tabs__tab--selected {
  border: none !important;
  color: red !important;
  transition: all 0.5s ease !important;
}

.searchBar {
  width: 100%;
}

.searchBar .formolder {
  padding: 0.3rem;
  width: 50%;
  margin: 0 auto;
  border: 1px solid gray;
}

.formolder input {
  border: none;
  outline: none;
  padding: 0.7rem;
  width: 50%;
}

.searchBar .formolder input {
  width: 90%;
}

.eventCenter input {
  margin-top: 1em;
  width: 50%;
  padding: 1.2em;
  border-radius: 5px;
  border: 0.5px solid #c4c4c4;
  font: 1em;
  color: #b4b4b4;
}

main.flex {
  margin-top: 5em;
}
main.flex > div:first-child {
  width: calc(100% - 300px);
}
main.flex > div:last-child {
  width: 300px;
  width: 300px;
  position: fixed;
  right: 0;
  top: 5em;
  box-shadow: 0 0 5px #00000029;
  /* padding: 1em; */
  bottom: 0;
}

.specialOffers {
  padding: 1.3em;
  margin-top: 1em;
  margin-right: 3em;
}

.allVendors,
.favlist {
  margin-top: 1em;
  margin-bottom: 3em;
}

.allVendors h3,
.favlist h3,
.specialOffers h3 {
  font-weight: 500;
  margin-bottom: 1em;
}

.specialOffers .card {
  width: calc(100% / 4 - 10px);
  background: white;
  box-shadow: 0 0 1px #00000029;
  border-radius: 0 0 5px 5px;
  opacity: 1;
}

.specialOffers .card img {
  height: 12.6em;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
  /* width: 100%; */
}

.specialOffers .card img {
  width: 100%;
}

.specialOffers .card {
  background-color: white;
  transition: box-shadow 0.25s linear;
  cursor: pointer;
}
.specialOffers .card:hover {
  box-shadow: 1px 1px 2px #00000038;
}

.card div {
  padding: 0.6em;
  position: relative;
}

.card p {
  font-size: 0.8em;
  font-weight: 500;
}
.card:not(.noTime) p:first-child {
  padding: 0.5em 1em;
  background-color: white;
  position: absolute;
  top: -2em;
  right: 0.6em;
  border-radius: 30px;
  font-size: 0.6em;
  box-shadow: 0 0 10px #00000029;
}

.eventCenter {
  margin-top: 0 !important;
}

.eventCenter .image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 1rem;
}

.allVendors .card,
.favlist .card {
  box-shadow: 0 0 3px #00000029;
  border-radius: 0 0 5px 5px;
  opacity: 1;
  margin-bottom: 1.5em;
}

.allVendors > div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
}

.order {
  display: flex;
  flex-direction: column;
  height: 100%;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.order > p {
  font-weight: 500;
  padding: 1em;
}
.order > p:first-child {
  margin-top: 2em;
}

.order > div > div {
  border-bottom: 0.5px solid #c4c4c4;
  padding: 1em;
}
.order > div:last-child {
  border-top: 0.5px solid #c4c4c4;
  /* border: 0;
    position: absolute;
    bottom: 0;
    width: 100%; */
  padding: 1rem;
}

.order .suggestionBox {
  background: #ffffff;
  border: 0.5px solid #b4b4b4;
  border-radius: 5px;
  height: 4.2em;
  margin-top: 1em;
  margin-bottom: 2em;
  overflow: hidden;
  margin-left: 1.2em;
  margin-right: 1.2em;
}

.order .suggestionBox > div {
  width: 56px;
  background-color: #ffa400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order .suggestionBox img {
  height: 1.15em !important;
  width: 1.15em !important;
  margin-top: 0 !important;
  filter: invert();
}

.order .suggestionBox input {
  width: 100%;
  padding-left: 1em;
  border: none;
}
.order p:nth-child(2) {
  color: #ff5353;
  padding-bottom: 2em;
  border-bottom: 0.5px solid #c4c4c4;
  padding-top: 0;
}

.order .numberBox {
  margin: 0 1em;
  border: 1px solid #c1c1c1;
  padding: 0.1em 2em;
  border-radius: 5px;
}

.order .cartControls {
  padding: 0.2em;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  height: 16px;
  width: 16px;
  align-self: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.order > div:first-child > div {
  margin-bottom: 1em;
}

.allVendors a {
  width: calc(100% / 3 - 20px);
  margin-right: 20px;
}
.allVendors a:last-child {
  margin-right: 0;
}

.allVendors .card img,
.favlist .card img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 150px;
  object-fit: cover;
  width: 100%;
}

.allVendors h3 {
  width: max-content;
  border-bottom: 4px solid #ffa400;
}

.allVendors .card div,
.favlist .card div {
  padding: 1.1em;
  position: relative;
}

.allVendors .card img {
  width: 90px;
  display: flex;
  margin: 0 auto;
  object-fit: contain !important;
}
.allVendors .card p:first-child,
.allVendors p:nth-child(2),
.favlist .card p:first-child,
.favlist p:nth-child(2) {
  font-size: 1em;
  right: 1em;
  color: black;
}

.allVendors .card span,
.favlist .card span {
  font-size: 0.8em;
}

.eventCenter > * {
  width: 100%;
}

.eventCenter > div {
  background-color: #ffa400;
  color: white;
  padding: 2rem 0.1em;
}

.eventCenter > div > * {
  width: 50%;
  align-items: center;
}

.eventCenter > div > div div {
  margin-left: 1em;
  font-weight: 600;
}

#selectEvent form,
.eventCenter form {
  background-color: white;
  display: flex;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  align-items: center;
  overflow: hidden;
}

#selectEvent form img,
.eventCenter form img {
  margin: 0 1em;
}

#selectEvent form input,
.eventCenter form input {
  border: 0;
  margin: 0;
}
#selectEvent form input:focus,
.eventCenter form input:focus {
  outline: none !important;
}

#selectEvent form button,
.eventCenter form button {
  border: none;
  background-color: black;
  color: white;
  padding: 0 1em;
  height: 100%;
}

.checkoutBtn {
  padding: 1em;
  margin-top: 1em;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

.order_history_card {
  width: 90px;
  object-fit: cover;
  height: 90px;
  border-radius: 1rem;
}
@media (max-width: 768px) {
  main.flex > div:first-child {
    width: 100%;
  }
  main.flex > div:last-child {
    display: none;
  }
  .allVendors > div {
    flex-direction: column;
  }
  .allVendors a {
    width: 100%;
    margin-right: 0;
  }
  .specialOffers .card {
    width: calc(100% / 3 - 10px);
  }
  .specialOffers {
    background-color: #f5f5f5;
    margin-right: 0;
  }
  .specialOffers .card:last-child {
    display: none;
  }
  .specialOffers .card img {
    height: 5.6em;
  }
  /* .checkoutBtn {
        margin: 1em;
    } */
  #selectEvent form,
  .eventCenter form {
    margin: 1rem;
  }

  .searchBar input {
    width: 90%;
  }
}
@media (max-width: 479px) {
  #selectEvent form button,
  .eventCenter form button {
    display: none;
  }
}

.footerNote{
    margin-top: -3rem;
    background-image: url('/footer.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 350px;
    /* border-radius: 10px; */
}

.footerNote .dark-overlay-footerNote {
    display: flex;
    background-color: rgba(0, 0, 0, 0.856);
    height: 350px;
    border-radius: 10px;
    justify-content: center;
    color: white;
}


.footerNote .dark-overlay-footerNote .footerNote-content {
    text-align: center;
    margin: auto 0;
}

.footerNote .dark-overlay-footerNote .footerNote-content .footerNote-content-partner {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 2rem;
}

.footerNote .dark-overlay-footerNote .footerNote-content .footerNote-content-join {
    margin-bottom: 2rem;
}

.footerNote .dark-overlay-footerNote .footerNote-Btn{
    background-color: #FF5353;
    text-align: center;
    padding: 15px 35px;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: small;
    box-shadow: 1px 2px 18px #1b1b1b4f;
}

.footerNote .footerNote-suggest{
    margin: 3rem 0 ;
    text-align: center;
    padding-bottom: 3rem;
}

.footerNote .footerNote-suggest span{
    color: #FF5353;
    font-weight: 600;
}


@media (max-width: 768px) {
    .footerNote{
        /* margin: -3rem 0 0 0 ; */
        background-image: url('/footer.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 376px;
        border-radius: 0;
    }

    .footerNote .dark-overlay-footerNote {
        display: flex;
        background-color: rgba(0, 0, 0, 0.856);
        height: 376px;
        border-radius: 0;
        justify-content: center;
        color: white;
    }

    .footerNote .dark-overlay-footerNote .footerNote-content .footerNote-content-partner {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 2rem;
    }

    .footerNote .dark-overlay-footerNote .footerNote-content .footerNote-content-join {
        margin-top: -1rem;
       font-size: 13px;
       padding: 0 3rem;
       width: 301px;
       /* width: 100%; */
    }

    .footerNote .dark-overlay-footerNote .footerNote-Btn{
        margin-top:2rem;
        padding: 8px 35px;
    }

    .footerNote .footerNote-suggest{
        margin: 3rem 0 ;
        text-align: center;
        font-size: 15px;
        padding-bottom: 3rem;
    }

    .footerNote .footerNote-suggest span{
        color: #FF5353;
        font-weight: 600;
    }
}
.defaultBtn {
    color: #FF5353;
    font-size: 1.143em !important;
    font-weight: 500;
    padding: 0.8em 3.6em;
    cursor: pointer;
    margin: 2em 0;
    border: 1px solid rgba(95, 95, 95, 0.2);
    box-shadow: 0 0 22px -1px darkgrey;
    border-radius: 5px;
    outline: none;
    background-color: #ffff;
}

.submitBtn {
    background-color: #FF5353;
    color: #ffff;
    border: none;
    border-radius: 5px;
    outline: none;
    padding: 0.8em 3.6em;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.reviewPostForm input,
.reviewPostForm textarea {
    outline: none;
    padding: 0.6rem;
    width: 100%;
}

.rating i {
    font-size: 18px;
    cursor: pointer;
    margin-left: 0.3rem;
}

.gold {
    color: gold;
}

.checked {
    font-weight: bold !important;
}

.spinnerHolder {
    width: 30px;
    height: 20px;
}

.spinnerHolder.big{
    width: 100%;
    height: 100%;
}   

.spinnerHolder.big .spinner{
    width: 150px;
    height: 150px;
}

.spinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid;
    border-top-color: white;
    transition: all 0.5s ease;
    animation: spin 0.9s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.reviewCard {
    box-shadow: 0 0 20px -1px #a9a9a994;
    border-radius: 10px;
    max-width: 350px;
    padding: 2rem;
}

.modal {
  background-color: #c4c4c4bf;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  padding: 1.5em;
}
.productModal {
  background: rgba(0, 0, 0, 0.1);
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  padding: 1.5em;
}

.close {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0.3rem;
  left: 1.1rem;
  cursor: pointer;
  font-size: 2.5rem;
  /* mix-blend-mode: difference; */
  z-index: 2;
}
.close-img {
  position: fixed;
  color: #444;
  mix-blend-mode: difference;
  font-size: 2.5rem;
  z-index: 2;
}

.modal2 {
  top: 5em;
  display: block;
}

.modal > div {
  background-color: white;
  border-radius: 10px;
  padding: 0.9em;
  max-width: 768px;
  width: 90%;
}

.modal .receipt {
  padding: 3rem;
  position: relative;
}

.modal .receipt [datatype="close"] {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.receipt button {
  justify-content: center;
}

#desktopNav {
  width: 250px;
  position: relative;
  display: flex;
  margin-top: -1.5em;
  margin-left: auto;
  margin-right: 6em;
  border-radius: 5px;
  padding: 0em;
}

#desktopNav > div {
  width: 100%;
  text-align: center;
  font-weight: 500;
  border-bottom: 0.5px solid #c4c4c490;
  padding: 1em;
}

#desktopNav > div a {
  margin-bottom: 1.6em;
  padding: 0.5em;
  border-radius: 5px;
}

#desktopNav > div span {
  border-top: 1px solid #c4c4c490;
}

#desktopNav > div p {
  margin-top: -0.8em;
  width: max-content;
  background: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #707070;
  padding: 0 1em;
}

#desktopNav > div a:last-child {
  margin-top: 0.7em;
  margin-bottom: 0;
}

#desktopNav ul {
  list-style: none;
}

#desktopNav ul li {
  padding: 0.2em 1em;
  cursor: pointer;
  margin-top: 0.1em;
  font-weight: 600;
  color: #707070;
}

#desktopNav li a {
  display: flex;
  align-items: center;
}

#desktopNav ul li img {
  width: 20px;
  height: 17px;
  filter: grayscale();
  fill: #707070;
}

#desktopNav ul li img[alt="profile"] {
  filter: invert();
}

#desktopNav ul li:hover {
  background-color: #c4c4c490;
  color: black;
}

.modal [datatype="close"] {
  font-size: 1.5em;
  cursor: pointer;
  margin-top: -5px;
  color: #ff5353;
}

.modal .justify-between {
  color: #ff5353;
  font-size: 1.2em;
  font-weight: 500;
}

.modal form {
  margin: 1em 0;
  margin-top: 0;
}

.modal form > input {
  width: 100%;
  margin: 1em 0;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 8px;
  font-size: 1em;
}

.modal form > p {
  color: #707070;
  margin-bottom: 3em;
}

.modal h2 {
  font-weight: unset;
  font-size: 1.2em;
}

.modal form > div {
  margin-top: 1em;
}

.modal button {
  width: 49%;
  padding: 0.5em;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
}

.modal button:first-child {
  background-color: #ffffff;
  color: #ff5353;
  border: 1px solid #ff5353;
}

.modal button:nth-child(2) {
  background-color: #ff5353;
  color: white;
  border: none;
}

.modal img {
  width: 5.5em;
  height: 5.5em;
  margin-right: 1em;
}

.modal .flex {
  color: #707070;
  font-size: 12px;
}

.modal button img {
  width: 1.2em;
  height: 1.2em;
}

#receipt > div {
  margin-top: 2em;
}

#receipt p {
  margin: 0.3em 0;
}

#receipt p:nth-child(2) {
  color: #ff5353;
}

#receipt p:nth-child(6) {
  margin-top: 1em;
  color: black;
}

#receipt button:first-child {
  background-color: #ff5353;
  color: white;
  border: none;
}

#receipt button:nth-child(2) {
  background-color: #ffa400;
  color: white;
  border: none;
}

#receipt button {
  align-items: center;
}

#selectEvent {
  padding: 2em 0;
  max-width: 700px;
  position: relative;
}

#selectEvent form {
  height: 3em;
  border: 0.5px solid #c4c4c4;
  font-size: 1em;
}

#selectEvent form img {
  width: 1.2em;
}

#selectEvent form input {
  margin: 0;
}

#selectEvent form button {
  border-radius: 0;
  width: auto !important;
}

#selectEvent div:first-child > div {
  display: flex;
  align-items: center;
  font-size: 1.3em;
}

#selectEvent > div:first-child {
  border-bottom: 0.5px solid #c4c4c4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

#selectEvent p[datatype="close"] {
  font-size: 2em;
  position: absolute;
  right: 1em;
  top: 1em;
}

#selectEvent ul {
  list-style: none;
  margin-top: 1em;
  width: 100%;
}

#selectEvent li {
  padding: 0.5em 0;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  background-color: transparent;
  transition: all 0.3s linear;
}

#selectEvent li:hover {
  background-color: #ff5353;
  color: white;
}

#selectEvent ul {
  padding: 0;
}

#selectEvent ul a {
  color: #000;
}
.options {
  /* padding: 1.5rem; */
  max-width: 600px;
  height: 100%;
  overflow: auto;
  position: relative;
  width: 90%;
  background-color: #fff;
}
.options::-webkit-scrollbar {
  display: none;
}
.option-header {
  padding: 1.5rem;
  margin-top: 2rem;
}

.option-header h1 {
  color: #000;
}

.options-main {
  padding: 1.5rem;
  height: 70%;
}
.options-main h1 {
  color: #000;
  font-size: 1.2rem;
}
.modal-img {
  position: relative;
  /* overflow: hidden; */
  /* border-radius: 10px; */
  width: 100%;
  height: 200px;
  /* height: 80px; */
  box-shadow: 1px 1px 7px #00000023;
  background-size: contain;
  background-position: center;
  display: block;
  background-repeat: no-repeat;
}
.fixed-footer {
  position: absolute;
  bottom: 5rem;
  overflow: hidden;
  max-width: 400px;
  margin: 0 auto;
}
.fixed-footer-main {
  overflow: hidden;
  height: 5rem;
  position: fixed;
  background-color: #fff;
  /* left: 0;
  right: 0; */
  max-width: 600px;
  width: 100%;

  display: flex;
  /* justify-content: ; */
  align-items: center;
}
.footer-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6rem;
}
.extras {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.extras-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.1rem;
  margin-top: 1.1rem;
  position: relative;
}
.extras-item::after {
  content: "";
  position: absolute;
  top: 2.6rem;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.extras-item p {
  color: #000;
}
.extras h5 {
  color: #000;
}
.extras p {
  margin-top: 0.5rem;
}
.extras-item-check {
  display: flex;
  align-items: center;
}
.modal-button {
  display: inline-flex;
  padding: 7px 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: #ff5353;
  /* width: 120px; */
}
@media screen and (max-width: 768px) {
  #selectEvent p[datatype="close"] {
    right: 0.8rem;
    top: 0.8rem;
  }
}
input[type="checkbox"] {
  height: 12px;
  width: 12px;
  /* visibility: hidden; */
  margin-right: 1.3rem;
  margin-left: 0.5rem;
  content: " ";
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #777;
  -ms-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  /* padding: 10px; */
}

@media (max-width: 650px) {
  #selectEvent {
    display: block;
  }
  #selectEvent ul {
    width: 100%;
    margin-right: 0;
    display: block !important;
    z-index: 1200;
  }
  .menu-Nav a {
    padding: 0;
    color: #fff;
    text-align: center;
    border-bottom: solid #ddd 0.5px;
  }
  #selectEvent li {
    padding: 0.5em 0;
    text-align: center !important;
    color: #000;
    width: 100%;
    margin-right: 0;
  }
  .tooltip {
    position: absolute;
    z-index: 900;
  }
  .options {
    /* padding: 1.5rem; */
    max-width: 500px;
    height: 90%;
    overflow: auto;
    position: relative;
    width: 100%;
    background-color: #fff;
  }
  .fixed-footer-main {
    max-width: 500px;
    width: 90%;
  }
  .footer-main {
    padding: 2rem;
  }
}
@media (max-width: 500px) {
  .options {
    /* padding: 1.5rem; */
    max-width: 400px;

    overflow: auto;
    position: relative;
    width: 100%;
  }
  .fixed-footer-main {
    max-width: 400px;
    width: 88%;
  }
  input[type="checkbox"] {
    margin-left: 0.2rem;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
    /* padding: 10px; */
  }
  .extras-item::after {
    top: 2.3rem;
  }
}
@media (max-width: 321px) {
  .fixed-footer-main {
    max-width: unset;
    width: 87%;
  }
  .footer-main {
    padding: 1rem;
  }
}

.paymentCardBox > div:first-child {
  width: 78px;
  height: 78px;
  border-radius: 5px;
  background-color: #e4e4e4;
}

.paymentBtnHolder{
  width: 70%;
  margin: 0 auto;
}

.logo--small{
  width: 60px;
}

.paymentCardBox > div:nth-child(2) p:first-child {
  color: #000000;
}

.paymentCardBox > div:nth-child(2) p:nth-child(2) {
  color: #707070;
}

.paymentCardBox > div:nth-child(2) p:last-child {
  color: #ff5353;
}

.paymentCardBox {
  width: calc(100% / 2 - 10px);
  margin-bottom: 1em;
}

#payments > .flex {
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

.payments__userData {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.payments__form {
  margin-left: 36px;
  width: 100%;
  align-self: center;
  margin-bottom: 40px;
}
.payments__formInput {
  margin-top: 20px;
  width: 100%;
}
.payments__label {
  font-size: 12px;
}
.payments__label > span {
  font-size: 12px;
  color: #ff5353;
}

.payments__input {
  width: 100%;
  border: 0;
  border: 2px solid #a9a9a966;
  border-radius: 4px;
  padding: 20px;
  height: 60px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .paymentCardBox {
    width: 100%;
  }
}

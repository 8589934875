body * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins;
}

.m_width {
  width: max-content !important;
}

.rAlign {
  float: right !important;
}

body,
html {
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none !important;
}

.textIsWhite {
  color: #fff !important;
}

.isRelative {
  position: relative;
}

.sectionHeight--short {
  min-height: 25vh;
}

.sectionHeight--mid {
  min-height: 40vh;
}
.no-scroll {
  height: 100vh;
  overflow: hidden;
}
.textIsPink {
  color: #ff5353;
}

.alignOnly {
  display: flex;
  align-items: center;
}

.spaceBetween {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.vAlign {
  text-align: center !important;
}

.isClickable {
  cursor: pointer;
}

.sectionText {
  font-size: 1rem;
}

.sectionText--mid {
  font-size: 0.9rem;
}

.sectionHead {
  font-size: 2rem;
}

.sectionHead--mid {
  font-size: 1.5rem;
}

#root {
  position: relative;
  overflow: hidden;
}

.dull {
  color: #707070;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.mt-2 {
  margin-top: 1em;
}

.text-center {
  text-align: center;
}

.align-items-center {
  display: flex;
  align-items: center;
}

button {
  cursor: pointer;
}

.textIsRed {
  color: #d0493a;
}

.primaryBG {
  background-color: #ff5353f9 !important;
  color: white;
  transition: all 0.2s ease-in-out;
  border: 5px solid transparent !important;
}

.primaryBG:hover {
  background-color: #ff5353ff !important;
  border: 5px solid #eb2f2f !important;
}

.secondaryBG {
  background-color: #ffa400 !important;
  color: white;
  transition: all 0.2s ease-in-out;
  border: 5px solid transparent !important;
}

.secondaryBG:hover {
  background-color: #ffa400 !important;
  border: 5px solid #de9204 !important;
}

@media (max-width: 768px) {
  .m_block {
    display: block;
  }
}
/* @media (max-width: 520px) {
  .spaceBetween {
    justify-content: center;
  }
} */

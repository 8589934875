.authContent{
    min-height: 100vh;
}

.alignOnly{
display: flex;
align-items: center;
}

.sectionHeight{
    min-height: 70vh;
}

.vAlign{
    text-align: center;
}

.sectionHead{
    font-size: 35px;
}

.sectionText{
    font-size: 16px;
}

.sectionText--mid{
    font-size: 13px;
}

.sectionText--small{
    font-size: 10px;
}

.textIsPink{
    color: #FF5353f9;
}

.authContent .defaultBtn{
        background-color: #FF5353;
        color: #ffff;
        border: none;
        border-radius: 5px;
        outline: none;
        padding: 0.8em 3.6em;
}

.authTextHolder{
    padding: 0 2rem;
}

.authFormCard{
        box-shadow: 0 0 20px -1px #a9a9a994;
        border-radius: 10px;
    padding: 3rem 2rem;
    width: 450px;
    margin: 0 auto;
    min-height: 45vh;
}

.inputHolder{
    transition: all 0.5s ease;
    border-bottom: 1px solid gray;
}

.authFormCard input{
    outline: none;
    padding: 0.6rem;
    border: none;
    width: 100%;
    background: transparent;
    transition: all .5s ease;
}

.authFormCard i {
    color: #FF5353;
}


.authFormCard input:focus{
    border-color: #FF5353 !important;
}

@media (max-width: 768px) {
    .authFormCard{
        width: 100%;
    }

    .mobileBottom{
        align-items: flex-end;
    }

    .sectionHeight{
        min-height: 50vh;
    }

    .authFormCard{
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
}
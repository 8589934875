.mealOrder {
  margin-top: 0.5rem;
  /* margin-top: 4rem; */
}

.mealOrder .card {
  border-radius: 5px;
  margin-bottom: 1.5em;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.mealOrder .card img {
  height: 500px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  object-fit: contain;
  width: 100%;
  padding: 0 2rem;
  /* background-color: darkgrey; */
  /* height: fit-content; */
}

.mealOrder .card div {
  padding: 1.1em;
  position: relative;
}

.mealOrder .card p:first-child {
  font-size: 1em;
  right: 1em;
  color: black;
}

.mealOrder .card span {
  font-size: 0.8em;
}

.mealOrder .card p:nth-child(3) {
  font-size: 1.6em;
  right: 1em;
  color: black;
}

.mealOrder .scrollY {
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px #00000029;
  scrollbar-width: none;
}

.mealOrder .scrollY p {
  cursor: pointer;
  padding: 1.1em 1.8em;
  white-space: nowrap;
}

.orderList {
  font-weight: 500;
}

.orderList > div {
  padding: 1.5em;
  border-bottom: 0.5px solid #c4c4c4;
}

.orderList > div img {
  width: 150px;
  margin-right: 1em;
  border-radius: 5px;
  object-fit: cover;
  height: auto;
  background-color: darkgrey;
}

.deets p {
  line-height: 1.3em;
  margin-bottom: 0.3em;
  margin-top: 0.3em;
  font-size: 12px;
}

.checkout-button-flex {
  justify-content: center;
}

.orderList > div a {
  display: inline-flex;
  justify-content: center;
  /* align-self: end; */
  padding: 0.2em 1.3em;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: #ff5353;
  width: 120px;
}

.subTotal {
  padding: 2em 0;
  box-shadow: 0px 0px 2px #00000029;
  background: white;
  margin: 2em 0;
}

.fullWidth {
  width: 100%;
}

.mealOrder .fullWidth a {
  /* margin-left: auto; */
  margin-top: 1.2rem;
}

.subTotal button {
  width: 100%;
  background-color: #ff5353;
  border: none;
  color: #fff;
  padding: 1em;
  margin-top: 1em;
  font-size: 1em !important;
  border-radius: 5px;
}

.subTotal h2 {
  font-weight: 500;
  font-size: 1.2em;
}

.subTotal p {
  text-align: right;
  font-size: 0.86em;
  color: #646464;
}

.subTotal span {
  color: #ff5353;
}

.subTotal img {
  width: 40px;
  height: 45px;
  object-fit: contain;
}

.activeTabMenu {
  border-bottom: 3px solid darkgrey;
}

.product-img-div {
  /* width: 40%; */
  /* height: 80px; */
  display: flex;
  position: relative;
  /* background-color: #ff5353; */
  padding: 0 1rem;

  /* height: 100%; */
}
.product-img-div-img {
  position: relative;
  overflow: hidden;

  border-radius: 10px;
  width: 150px;
  height: 150px;
  /* height: 80px; */
  box-shadow: 1px 1px 7px #00000023;
  background-size: cover;
  background-position: center;
  display: block;
  background-repeat: no-repeat;
}
/* 
.product-img-div-img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 30px;
} */

.product-img-div2 {
  width: 100%;
  flex-direction: column;
  margin-left: 2rem;
}

.orderList > div img {
  width: 60%;
  margin-right: 0;
  height: 10%;
}

.fix {
  background: #fff;
  padding: 40px;
  position: fixed;
  /* display: block; */
  left: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* z-index: 10; */
  height: 2rem;
  box-shadow: 0px 0px 9px 1px #3c37373d;
  align-items: center;
  display: flex;
}
.hide {
  display: none;
}

@media (max-width: 768px) {
  .mealOrder .card {
    border-radius: 0;
  }

  .mealOrder .card img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-radius: 0;
    width: 100%;
    height: 320px;
    object-fit: contain;
    object-position: center;
  }

  .mealOrder {
    margin-top: 0.8rem;
    /* margin-top: 5rem; */
  }
}

@media (max-width: 650px) {
  .product-img-div {
    width: 40%;
    /* background-color: #FF5353; */
    padding: 0;
  }

  .product-img-div-img {
    height: 120px;
    width: 120px;
  }

  .product-img-div2 {
    width: 100%;
    padding: 0 10px;
    /* background-color: aqua; */
  }

  .orderList > div img {
    width: 100%;
    margin-right: 0;
    height: 70%;
  }

  .mealOrder .card img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-radius: 0;
    width: 100%;
    height: 320px;
    object-fit: contain;
  }
}

#offers .flex>div, #fav .flex>div {
    box-shadow: 0px 0px 3px #0000004D;
    border-radius: 5px;
    background: white;
    padding: 1.2em;
}

#offers>div {
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 5em;
}

.offerCard {
    width: calc(100% / 2 - 10px);
    margin: 1em 0;
}

.offerCard>p:first-child, #fav>p:first-child {
    padding: 5px 10px;
    background-color: #E4E4E4;
    border-radius: 5px;
    margin: 0 0 1em;
    width: max-content;
    font-weight: 500;
    font-size: 1.2em;
}

.offerCard span {
    color: #FF5353;
}

#offers .flex h3, #fav>p:first-child {
    font-size: 1em;
    margin-bottom: 1em;
}

.offerCard>div p {
    color: #707070;
}

.offerCard div {
    margin-top: 1em;
}

.offerCard button {
    background: #FF5353;
    border-radius: 5px;
    padding: 7px 10px;
    border: none;
    font-size: 1em;
    color: white;
}

.offerCard a {
    margin: auto 0;
    margin-left: 1em;
    color: #FF5353;
    font-size: 1em;
}

@media (max-width: 768px) {
    .offerCard {
        width: 100%;
    }
    #offers>div {
        flex-direction: column;
        padding-right: 1.2em;
    }
}